import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import ResourceTable from '../../components/ResourceTable/ResourceTable';

class Forms extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allFormsListJson;
    const mappedResources = resources.map(resource => resource.node);
    return (
      <Layout>
        <Helmet
          title="VEYM | Forms"
          meta={[
            {
              name: 'description',
              content:
                'VEYM Official Forms - Camp ID, Post Camp Completion, Promotion',
            },
          ]}
        />
        <PageHeading>Forms</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Forms</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <h3>Mẫu Đơn</h3>
          <br />
          <br />
          Please submit at least three weeks in advance for us to process. Thank
          you.
          <br />
          <br />
          <ResourceTable resources={mappedResources} striped />
        </Container>
      </Layout>
    );
  }
}

export default Forms;

export const pageQuery = graphql`
  query FormsQuery {
    allFormsListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Forms',
  category: 'resources',
  path: '/resources/forms',
  description: 'Forms',
  tags:
    'resource, forms, mau don, promotion, thang cap, nien liem, ma so sa mac, hoan tat sa mac,  ',
};
